import Skeleton from '@carbonfact/ui-components/src/Skeleton';
import type { Table } from '@tanstack/react-table';
import classNames from 'classnames';
import { uniqueId } from 'lodash';

export default function TableLoadingSkeleton<T>({
  table,
}: {
  table: Table<T>;
}) {
  return new Array(table.getState().pagination.pageSize).fill(null).map(() => (
    <tr key={`loading-${uniqueId()}`} className="skeleton-row">
      {table.getVisibleFlatColumns().map((column) => (
        <td
          key={`loading-${uniqueId()}`}
          className={classNames('px-2 pt-2', column.columnDef.meta?.className)}
          style={{
            width: column.getSize(),
          }}
        >
          <Skeleton width="w-full" height="h-5" />
        </td>
      ))}
    </tr>
  ));
}
