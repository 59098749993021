import { Button } from '@carbonfact/ui-components/src/Button';
import Icon from '@carbonfact/ui-components/src/Icon';
import ClickableDropdown from 'app/components/ClickableDropdown';
import { useTranslations } from 'next-intl';
import FiltersSelectionWidget from './primitives/FiltersSelectionWidget';
import type { AvailableFilters, FiltersPickerProps } from './types';
import { findValueLabel } from './utils';

export default function FiltersPicker<Filters extends AvailableFilters>({
  availableFilters,
  selectedFilters = {},
  onFiltersChange,
}: FiltersPickerProps<Filters>) {
  const t = useTranslations();
  const totalFiltersCount = Object.values(selectedFilters)
    .flat()
    .filter((v) => v !== undefined).length;

  if (availableFilters.length === 0) {
    return (
      <Button.Default variant="secondary" disabled>
        <Icon
          className="opacity-50"
          icon={{
            source: 'hero',
            name: 'FunnelIcon',
            type: totalFiltersCount > 0 ? 'solid' : 'line',
          }}
        />
        {t('FiltersPicker.addFilter')}
      </Button.Default>
    );
  }

  return (
    <ClickableDropdown
      disclosure={
        <Button.Default variant="secondary">
          <Icon
            icon={{
              source: 'hero',
              name: 'FunnelIcon',
              type: totalFiltersCount > 0 ? 'solid' : 'line',
            }}
          />
          {totalFiltersCount > 0 ? (
            <>
              <span className="font-normal">{t('FiltersPicker.filters')}</span>{' '}
              {totalFiltersCount > 3
                ? `${totalFiltersCount} ${t('FiltersPicker.selected')}`
                : Object.values(selectedFilters)
                    .flat()
                    .filter((v) => v !== undefined)
                    .map((value) => findValueLabel(availableFilters, value))
                    .join(', ')}
            </>
          ) : (
            <>{t('FiltersPicker.addFilter')}</>
          )}
        </Button.Default>
      }
    >
      <FiltersSelectionWidget
        availableFilters={availableFilters}
        selectedFilters={selectedFilters}
        onFiltersChange={(filters) => onFiltersChange?.(filters)}
      />
    </ClickableDropdown>
  );
}
