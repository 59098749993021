import { Button } from '@carbonfact/ui-components/src/Button';
import Icon from '@carbonfact/ui-components/src/Icon';
import type { Table } from '@tanstack/react-table';
import useEndpoint from 'app/hooks/useEndpoint';
import { Analytics } from 'app/lib/analytics';
import { useTranslations } from 'next-intl';
import { useCallback, useMemo, useState } from 'react';
import convertCellToCsvValue, {
  type CsvCellValue,
  type RawCellValue,
} from '../utils/convert-cell-to-csv-value';

// How many rows of a table to fetch in a single query when exporting.
// The export code loops through pages of the table, just like when browsing
// manually, but of course doesn't need to spend as much time rendering them or
// making clean UI, so it can go much faster.
// A big page size here is good because it reduces network overhead from running
// a lot of smaller HTTP transactions, instead leveraging a single connection to
// transfer a lot of data at once.
const EXPORT_FETCH_PAGE_SIZE = 50_000;

interface ExportTableButtonProps<T> {
  table: Table<T>;
  serverSideRowPagesIterator?: (
    pageSize: number,
  ) => AsyncGenerator<T[], void, unknown>;
}

export default function ExportTableButton<T>({
  table,
  serverSideRowPagesIterator,
}: ExportTableButtonProps<T>) {
  const { data: metadata } = useEndpoint('/metadata');
  const [loading, setLoading] = useState(false);
  const t = useTranslations();
  const accessorColumns = table
    .getAllFlatColumns()
    .filter((column) => typeof column.accessorFn !== 'undefined');

  const rowsIterator = useMemo(() => {
    if (serverSideRowPagesIterator) {
      return async function* iterateOverRemoteRows() {
        for await (const rowPage of serverSideRowPagesIterator(
          EXPORT_FETCH_PAGE_SIZE,
        )) {
          for (const row of rowPage) {
            // bit of a hack here: we're creating a fake row object to get
            // the printed row values for rows that haven't been rendered in the table.
            // this is necessary because in server-side paginated tables, react-table
            // only sees the current page of rows at a given time.
            yield {
              getValue: (columnId: string) =>
                accessorColumns
                  .find((column) => column.id === columnId)
                  ?.accessorFn?.(row, 0) as RawCellValue,
            };
          }
        }
      };
    }

    // this function doesn't actually need to be async, but we make it to be consistent with the server-side case, easier to handle later
    return async function* iterateOverLocalRows() {
      for (const row of table.getPrePaginationRowModel().flatRows) {
        yield row;
      }
    };
  }, [
    table.getPrePaginationRowModel,
    serverSideRowPagesIterator,
    accessorColumns,
  ]);

  const exportTable = useCallback(async () => {
    Analytics.capture('exported_table', {
      url: window.location.pathname,
    });
    setLoading(true);
    let csv = '';

    // Print headers
    csv += `${accessorColumns
      .map((column) => {
        let headerString = '';
        if (
          column.parent?.columnDef.header &&
          typeof column.parent.columnDef.header === 'string'
        ) {
          // Print column group headers
          headerString += column.parent.columnDef.header += ' - ';
        }

        if (typeof column.columnDef.header === 'string') {
          headerString += column.columnDef.header;
        } else {
          headerString += column.id;
        }

        return convertCellToCsvValue(headerString);
      })
      .join(',')}\n`;

    // Print rows
    for await (const row of rowsIterator()) {
      const rowCells: CsvCellValue[] = [];
      for (const columnId of accessorColumns.map((column) => column.id)) {
        rowCells.push(convertCellToCsvValue(row.getValue(columnId)));
      }
      csv += `${rowCells.join(',')}\n`;
    }

    const csvFile = new File(
      [csv],
      `Carbonfact ${metadata?.accountName}${window.location.pathname.replaceAll('/', ' ').replaceAll(' en', '').replaceAll(' fr', '')} ${new Date().toLocaleDateString().replaceAll('/', '-')}.csv`,
      { type: 'text/csv', endings: 'native' },
    );

    const csvUrl = URL.createObjectURL(csvFile);

    // Just using window.open won't conserve the file name,
    // go through a hidden link instead.
    const link = document.createElement('a');
    link.href = csvUrl;
    link.download = csvFile.name;
    link.style.display = 'none';
    document.body.appendChild(link);
    link.click();

    setLoading(false);

    // Clean up once download has started
    setTimeout(() => {
      document.body.removeChild(link);
      URL.revokeObjectURL(csvUrl);
    }, 5000);
  }, [accessorColumns, rowsIterator, metadata?.accountName]);

  return (
    <Button.Default
      variant="secondary"
      onClick={() => void exportTable()}
      loading={loading}
    >
      <Icon
        icon={{
          source: 'hero',
          name: 'ArrowDownTrayIcon',
          type: 'solid',
        }}
      />
      {t('ExportTableButton.export')}
    </Button.Default>
  );
}
