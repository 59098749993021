import { Button } from '@carbonfact/ui-components/src/Button';
import Icon from '@carbonfact/ui-components/src/Icon';
import { PopoverButton } from '@headlessui/react';
import SearchBar from 'app/components/SearchBar';
import classNames from 'classnames';
import { isEqual } from 'lodash';
import { useTranslations } from 'next-intl';
import { useCallback, useEffect, useMemo, useState } from 'react';
import type {
  AvailableFilters,
  FiltersPickerProps,
  SelectedFilters,
} from '../types';
import { findFirstSelectedValue, findValueLabel } from '../utils';

const SELECT_ALL_VALUE = '$all$' as const;

export default function FiltersSelectionWidget<
  Filters extends AvailableFilters,
>({
  availableFilters,
  onFiltersChange,
  selectedFilters: initialSelectedFilters,
}: Required<FiltersPickerProps<Filters>>) {
  const [selectedFilters, setSelectedFilters] = useState<
    SelectedFilters<Filters>
  >(initialSelectedFilters);

  useEffect(() => {
    setSelectedFilters({ ...initialSelectedFilters }); // make sure to copy the object from the heap rather than the stack pointer
  }, [initialSelectedFilters]);

  const [shownDimensionKey, setShownDimensionKey] = useState<
    Filters[number]['key'] | null
  >(null);

  const shownDimension: Filters[number] | undefined = availableFilters.find(
    ({ key }) => key === shownDimensionKey,
  );

  const getCountOfSelectedValuesForDimension = useCallback(
    (dimensionKey: typeof shownDimensionKey): number => {
      const selectedValues = Object.entries(selectedFilters).find(
        ([key]) => key === dimensionKey,
      )?.[1];

      const multiselect = availableFilters.find(
        ({ key }) => key === dimensionKey,
      )?.multiselect;

      if (!selectedValues) return 0;
      if (multiselect) {
        if (!Array.isArray(selectedValues)) return 0;
        return selectedValues.length;
      }
      return 1;
    },
    [selectedFilters, availableFilters],
  );

  const isFilterValueSelected = useCallback(
    <D extends Filters[number]>(
      dimension: D,
      dimensionKey: Filters[number]['key'],
      value: D['options'][number]['value'],
    ) => {
      // Single select
      if (!dimension.multiselect) {
        return selectedFilters[dimensionKey] === value;
      }
      // Multiselect
      const dimensionSelectedValues = selectedFilters[dimensionKey];
      if (!Array.isArray(dimensionSelectedValues)) return false;
      if (value === SELECT_ALL_VALUE)
        return dimensionSelectedValues.length === dimension.options.length;
      return dimensionSelectedValues.includes(value);
    },
    [selectedFilters],
  );

  const selectFilterValue = useCallback(
    <D extends Filters[number]>(
      dimension: D,
      dimensionKey: Filters[number]['key'],
      value: D['options'][number]['value'],
    ) => {
      /*
       * Single-select
       */
      if (!dimension.multiselect) {
        if (selectedFilters[dimensionKey] === value) {
          // Deselect the option
          delete selectedFilters[dimensionKey];
        } else {
          selectedFilters[dimensionKey] =
            value as SelectedFilters<Filters>[typeof dimensionKey]; // we know the value matches the dimension type
        }
        setSelectedFilters({ ...selectedFilters }); // Trigger a re-render by making a new object
        return;
      }

      /*
       * Multi-select
       */

      const dimensionSelectedValues = selectedFilters[dimensionKey];

      if (Array.isArray(dimensionSelectedValues)) {
        const newValue = value;
        const isValueIncluded = dimensionSelectedValues.includes(newValue);

        const updatedValues = isValueIncluded
          ? dimensionSelectedValues.filter((value) => value !== newValue)
          : [...dimensionSelectedValues, newValue];

        setSelectedFilters((prev) => {
          // Remove the dimensionKey entirely if there are no selected values
          if (updatedValues.length === 0) {
            delete prev[dimensionKey];
            return { ...prev }; // Return a new object to properly trigger a re-render
          }

          return {
            ...prev,
            [dimensionKey]: updatedValues,
          };
        });
      } else {
        // Handle the case where we didn't select any values in the dimension yet,
        // = selectedFilters[dimensionKey] is undefined
        setSelectedFilters((prev) => ({
          ...prev,
          [dimensionKey]: [value],
        }));
      }
    },
    [selectedFilters],
  );

  const toggleSelectAllDimensionValues = useCallback(
    (dimension: Filters[number]) => {
      if (!dimension.multiselect) return;

      if (isFilterValueSelected(dimension, dimension.key, SELECT_ALL_VALUE)) {
        setSelectedFilters((prev) => {
          delete prev[dimension.key as keyof SelectedFilters<Filters>];
          return { ...prev };
        });
      } else {
        setSelectedFilters((prev) => ({
          ...prev,
          [dimension.key]: dimension.options.map((option) => option.value),
        }));
      }
    },
    [isFilterValueSelected],
  );

  const totalFiltersCount = Object.values(selectedFilters)
    .flat()
    .filter((v) => v !== undefined).length;

  // Enable the apply filters button once changes have been made
  const hasAnyFilterChanged = useMemo(
    () => !isEqual(initialSelectedFilters, selectedFilters),
    [initialSelectedFilters, selectedFilters],
  );

  // Search functionality
  const [optionsSearch, setOptionsSearch] = useState('');
  const t = useTranslations();
  useEffect(() => {
    // Reset search when navigating to another dimension
    if (typeof shownDimensionKey !== 'undefined') {
      setOptionsSearch('');
    }
  }, [shownDimensionKey]);

  return (
    <section className="bg-white p-3 min-w-[330px]">
      <header className="flex flex-row justify-between items-center">
        <div
          className={classNames(
            'flex flex-row justify-start items-center',
            shownDimension && 'cursor-pointer',
          )}
          onClick={() => shownDimension && setShownDimensionKey(null)}
        >
          {shownDimension && (
            <Icon
              height={24}
              width={24}
              icon={{ source: 'local', name: 'direction-left', type: 'line' }}
            />
          )}
          <h3 className="font-medium text-md">
            {shownDimension ? shownDimension.label : t('FiltersPicker.filters')}
          </h3>
        </div>

        <Button.Default
          onClick={() => setSelectedFilters({})}
          variant="invisible"
          disabled={totalFiltersCount === 0}
        >
          <span
            className={classNames(
              'text-sm font-normal',
              totalFiltersCount === 0 ? 'text-carbon-400' : 'text-carbon-700',
            )}
          >
            {t('FiltersPicker.clearAll')}
          </span>
        </Button.Default>
      </header>
      <hr className="mt-2" />
      {
        // Show search bar when there are >10 options
        shownDimension && shownDimension.options.length > 10 && (
          <SearchBar
            className="mt-2"
            value={optionsSearch}
            onChange={setOptionsSearch}
          />
        )
      }
      <ul className="flex flex-col gap-1 my-2 max-h-[345px] overflow-y-auto">
        {shownDimensionKey && shownDimension
          ? /*
             * Show list of dimension options
             */
            [
              ...(shownDimension.multiselect &&
              shownDimension.options.length > 3 &&
              !optionsSearch
                ? [
                    {
                      label: t('Dropdown.Multiselect.selectAll'),
                      value: SELECT_ALL_VALUE,
                    },
                  ]
                : []),
              ...shownDimension.options.filter((option) => {
                if (!optionsSearch) return true;

                // Apply search
                return String(option.label ?? option.value)
                  .toLowerCase()
                  .includes(optionsSearch.toLowerCase());
              }),
            ].map((option) => (
              <li
                key={option.value}
                className={classNames(
                  'p-2 flex flex-row flex-nowrap justify-between items-center gap-2 cursor-pointer hover:bg-gray-50 rounded-md',
                  isFilterValueSelected(
                    shownDimension,
                    shownDimensionKey,
                    option.value,
                  ) && 'font-medium',
                )}
                onClick={() => {
                  if (option.value === SELECT_ALL_VALUE) {
                    toggleSelectAllDimensionValues(shownDimension);
                    return;
                  }
                  selectFilterValue(
                    shownDimension,
                    shownDimensionKey,
                    option.value,
                  );
                }}
              >
                <span className="text-sm">{option.label ?? option.value}</span>

                {isFilterValueSelected(
                  shownDimension,
                  shownDimensionKey,
                  option.value,
                ) && (
                  <Icon
                    height={15}
                    width={15}
                    icon={{
                      source: 'local',
                      name: 'check',
                      type: 'solid',
                    }}
                  />
                )}
              </li>
            ))
          : /*
             * Show list of available dimensions
             */
            availableFilters.map((dimension) => (
              <li
                key={dimension.key}
                className="p-2 flex flex-row flex-nowrap justify-between items-center cursor-pointer hover:bg-gray-50 rounded-md"
                onClick={() => setShownDimensionKey(dimension.key)}
              >
                <span className="text-sm">{dimension.label}</span>
                <div className="flex flex-row justify-end items-center gap-1">
                  {/*
                   * Show count of selected filters within dimension
                   */}
                  {getCountOfSelectedValuesForDimension(dimension.key) > 0 && (
                    <span className="text-sm font-medium px-1 bg-carbon-100 rounded-md max-w-[100px] whitespace-nowrap overflow-hidden text-ellipsis">
                      {getCountOfSelectedValuesForDimension(dimension.key) === 1
                        ? String(
                            findValueLabel(
                              availableFilters,
                              findFirstSelectedValue(
                                selectedFilters,
                                dimension.key,
                              ),
                            ),
                          )
                        : `${getCountOfSelectedValuesForDimension(dimension.key)} ${t('FiltersPicker.selected')}`}
                    </span>
                  )}
                  <Icon
                    height={20}
                    width={20}
                    icon={{
                      source: 'local',
                      name: 'direction-right',
                      type: 'line',
                    }}
                  />
                </div>
              </li>
            ))}
      </ul>
      <hr className="mb-2" />
      <footer className="flex flex-row flex-nowrap justify-between items-center gap-2">
        <span className="font-medium text-sm whitespace-nowrap text-carbon-500">
          {totalFiltersCount} {t('FiltersPicker.selectedFilters')}
        </span>
        <PopoverButton
          as={Button.Default}
          onClick={() => onFiltersChange(selectedFilters)}
          disabled={!hasAnyFilterChanged}
          className={classNames(
            hasAnyFilterChanged
              ? 'bg-carbon-500 text-white'
              : 'bg-carbon-100 text-carbon-500',
          )}
        >
          {t('FiltersPicker.apply')}
        </PopoverButton>
      </footer>
    </section>
  );
}
