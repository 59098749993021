import type { PaginatedResponse } from '@carbonfact/shared/src/types/platform';
import type { SortingState as TanStackSortingState } from '@tanstack/react-table';
import { FetcherError } from 'app/context/SWRContext';
import type { UsePaginatedEndpointReturnType } from 'app/hooks/usePaginatedEndpoint';

import type { AvailableFilters } from 'app/components/FiltersPicker/types';
import { Table as DefaultTable, type TableProps } from '../Default';

// re-export for easier discoverability
export type SortingState = TanStackSortingState;

type ServerPaginatedTableProps<
  RowData extends object,
  PossibleRouteType extends string,
  Filters extends AvailableFilters,
> = Omit<
  TableProps<RowData, PossibleRouteType, Filters>,
  'data' | 'hasPagination'
> & {
  paginatedResponse: UsePaginatedEndpointReturnType<PaginatedResponse<RowData>>;
};

// This variant takes a PaginatedEndpointResponse directly and uses it to render
// a table whose data pagination is controlled server-side
export function ServerPaginated<
  RowData extends object,
  PossibleRouteType extends string,
  Filters extends AvailableFilters,
>({
  paginatedResponse,
  ...props
}: ServerPaginatedTableProps<RowData, PossibleRouteType, Filters>) {
  if (
    !paginatedResponse ||
    (paginatedResponse.error instanceof FetcherError &&
      paginatedResponse.error.status === 404)
  ) {
    return (
      <DefaultTable<RowData, PossibleRouteType, Filters>
        {...props}
        data={[]}
        isLoading={props.isLoading || false}
        hasPagination={false}
      />
    );
  }

  return (
    <DefaultTable<RowData, PossibleRouteType, Filters>
      {...props}
      data={paginatedResponse.page ?? []}
      isLoading={props.isLoading || paginatedResponse.isLoading}
      hasPagination
      serverSideRowPagesIterator={paginatedResponse.iterateOverAllPages}
      customReactTableOptions={{
        ...props.customReactTableOptions,
        manualPagination: true,
        manualSorting: true,
        pageCount: paginatedResponse?.totalPageCount ?? -1,
        rowCount: paginatedResponse?.totalRowCount ?? -1,
        state: {
          pagination: {
            pageIndex: paginatedResponse.pageNumber - 1,
            pageSize: paginatedResponse.pageSize,
          },
          sorting: props.customReactTableOptions?.state?.sorting,
        },
        onPaginationChange: (updater) => {
          const newPagination =
            typeof updater === 'function'
              ? updater({
                  pageIndex: paginatedResponse.pageNumber - 1,
                  pageSize: paginatedResponse.pageSize,
                })
              : updater;

          paginatedResponse.setPageSize(newPagination.pageSize);
          paginatedResponse.setPageNumber(newPagination.pageIndex + 1);
        },
      }}
    />
  );
}
