import { z } from 'zod';
import type { AvailableFilters, SelectedFilters } from './types';

// Useful to store filters as JSON objects in URL params or similar
export const selectedeFiltersSchema = z.record(z.string());
export const multiselectSelectedeFiltersSchema = z.record(z.array(z.string()));

export function findValueLabel<V>(
  availableFilters: AvailableFilters,
  value: V,
): string | V {
  for (const dimension of availableFilters) {
    for (const option of dimension.options) {
      if (option.value === value) {
        return option.label || value;
      }
    }
  }
  return value;
}

export function findFirstSelectedValue<Filters extends AvailableFilters>(
  selectedFilters: SelectedFilters<Filters>,
  dimensionKey: Filters[number]['key'],
): string {
  const selectedValues = selectedFilters[dimensionKey];
  if (Array.isArray(selectedValues)) {
    return String(selectedValues.at(0));
  }
  return String(selectedValues ?? 'None');
}
